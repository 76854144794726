import { withLoading } from './CommonActions';
import { addEvent } from './EventActions';
import { Events } from '../services/Constants';
import processorService from '../services/processorService';
import { NotificationManager } from 'react-notifications';
import { dispatchRiskReturnResponse } from './UIActions';
import Constants from '../services/Constants';

export const exportStudyToExcel = withLoading(() => (dispatch, getState) => {
  const {
    ui: { toExportStudy },
  } = getState();

  dispatch(addEvent(Events.EXPORT_STUDY_EXCEL, toExportStudy.study.studyId));
  return processorService()
    .exportStudyDataToExcel(toExportStudy)
    .then(() => {
      NotificationManager.success('Study successfully exported to Excel', 'Success', Constants.POP_UP_TIME);
    })
    .catch(() => {
      NotificationManager.error('Unable to export study', 'Error', Constants.POP_UP_TIME);
    });
});

export const calcRiskReturn = withLoading(() => (dispatch, getState) => {
  const {
    ui: {
      selectedStudy: { study, assetPools },
    },
  } = getState();

  const riskReturnRequest = {
    versionId: study.assumptionId,
    item: assetPools.map((asset) => ({
      id: asset.assetPoolId,
      mixPk: asset.mixId,
      cash: asset.aum,
    })),
  };

  dispatch(addEvent(Events.CALC_RISK_RETURNS, study.studyId));
  return processorService()
    .calcRiskReturn(riskReturnRequest)
    .then(({ data }) => {
      dispatch(dispatchRiskReturnResponse(data, new Date().toISOString()));
      NotificationManager.success('Risk Return successfully calculated', 'Success', Constants.POP_UP_TIME);
    })
    .catch((e) => {
      NotificationManager.error('Unable to calculate Risk Return', 'Error', Constants.POP_UP_TIME);
    });
});

export const downloadAssumptionsPdf = withLoading(() => {
  return (dispatch, getState) => {
    const {
      ui: {
        okta,
        selectedStudy: {
          study: { assumptionId },
        },
      },
      resources: {
        assumptions: { data },
      },
    } = getState();
    const assumption = data.find((item) => item.versionPk === assumptionId);
    return processorService().getAssumptionsPdf(okta?.email, assumptionId, assumption.versionName.replaceAll(' ', '-'));
  };
});

export const downloadOperationGuidePdf = withLoading(() => (dispatch, getState) => {
  const {
    ui: { okta },
  } = getState();
  return processorService().getOperationGuidePdf(okta?.email);
});
