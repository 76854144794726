import React, { Component } from 'react';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Label,
  UncontrolledTooltip,
} from 'reactstrap';

import { Checkbox } from 'primereact/checkbox';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';

import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { OktaAuth } from '@okta/okta-auth-js';
import {
  cloneStudy,
  deleteStudy,
  dispatchCheckStudyValidity,
  dispatchDisplaySingleColumnLayout,
  dispatchOriginalVersion,
  dispatchPrinting,
  dispatchSelectedClient,
  dispatchStudyName,
  dispatchToExportStudy,
  exportStudyToExcel,
  isStudyModified,
  loadAssumptions,
  loadClients,
  loadRiskProxies,
  loadStudiesData,
  loadStudyData,
  loadStudyUsers,
  loadStudyUsersAccess,
  printStudyData,
  saveStudy,
  selectMeasure,
  selectStudy,
  setMustRevert,
  toggleSettingsMenu,
} from '../../actions';
import AssetAllocation from '../AssetAllocation/AssetAllocation';
import AssetPools from '../AssetPools/AssetPools';
import LevelSecurity from '../Cards/LevelSecurity';
import CashFlows from '../CashFlows/CashFlows';
import EconomicScenarios from '../EconomicScenarios/EconomicScenarios';
import FinancialMetrics from '../FinancialMetrics/FinancialMetrics';
import config from '../Login/config';
import ManagementButton from '../ManagementButton';
import OtherFinancialProjections from '../OtherFinancialProjections/OtherFinancialProjections';
import StudySettingsPanel from '../SettingsMenu/StudySettingsPanel';
import {
  ActionButtons,
  BoxDisplay,
  Boxes,
  BoxInput,
  LastCalculation,
  StudyHeader,
  StudyStyle,
  StudyTittle,
  ToggleText,
} from './styles';

const oktaAuth = new OktaAuth(config.oidc);

export class Study extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropMenuOpen: false,
      loadAssumptionsRequested: false,
      loadClientsRequested: false,
      loadRiskProxiesRequested: false,
      loadStudyDataRequested: false,
      loaded: false,
      returnToCards: false,
      study: props.studyProps.study,
      securityVisible: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const prevPropsStudyStringified = JSON.stringify(prevProps.studyProps.study);
    const thisPropsStudyStringified = JSON.stringify(this.props.studyProps.study);

    const prevPropsStudyResourcesStringified = JSON.stringify(prevProps.studyResources);
    const thisPropsStudyResourcesStringified = JSON.stringify(this.props.studyResources);

    const prevPropsOriginalVersionStringified = JSON.stringify(prevProps.originalVersion);
    const thisPropsOriginalVersionStringified = JSON.stringify(this.props.originalVersion);

    const propsStudyHasChanges = prevPropsStudyStringified !== thisPropsStudyStringified;
    const propsStudyResourcesHasChanges = prevPropsStudyResourcesStringified !== thisPropsStudyResourcesStringified;
    const propsOriginalVersionHasChanges = prevPropsOriginalVersionStringified !== thisPropsOriginalVersionStringified;

    const propsAssumptionsIsEmpty = !this.props.assumptions.length;
    const stateLoadAssumptionsNotRequested = !this.state.loadAssumptionsRequested;
    const stateLoadClientsNotRequested = !this.state.loadClientsRequested;
    const stateLoadRiskProxiesNotRequested = !this.state.loadRiskProxiesRequested;
    const stateLoadStudyDataNotRequested = !this.state.loadStudyDataRequested;
    const stateLoadedHasChanges = prevState.loaded !== this.state.loaded;
    const propsPrintingHasChanges = prevProps.printing !== this.props.printing;
    const stateStudyIsEmpty = !this.state.study;
    const propsStudyIsEmpty = !this.props.studyProps.study;
    const propsStudyExists = !propsStudyIsEmpty;
    const propsStudyResourcesIsEmpty = !this.props.studyResources;
    const propsStudyResourcesExists = !propsStudyResourcesIsEmpty;
    const propsSelectedClientIsEmpty = !this.props.selectedClient?.clientGuid;
    const propsOktaTokenExists = this.props.okta?.token;

    const thereAreUnsavedChanged = this.props.isStudyModified();

    if (propsStudyHasChanges && ((stateStudyIsEmpty && propsStudyExists) || this.stateAndPropsStudyIsNotSame())) {
      this.setState({ study: this.props.studyProps.study });
    }

    if (propsPrintingHasChanges && this.props.printing) {
      setTimeout(() => this.props.printStudyData(), 200);
    }

    if (propsOriginalVersionHasChanges || propsStudyHasChanges) {
      const tokens = oktaAuth?.tokenManager.getTokensSync();
      window.onbeforeunload =
        oktaAuth?.isAuthenticated() && !oktaAuth.tokenManager.hasExpired(tokens.accessToken) && thereAreUnsavedChanged
          ? () =>
              'There are unsaved changes. Are you sure you want to leave this page?\nClick Cancel to stay and finish your work. Click OK to discard your changes.'
          : undefined;
    }

    if (propsOktaTokenExists) {
      if (stateLoadClientsNotRequested && propsSelectedClientIsEmpty) {
        this.setState({ loadClientsRequested: true });
        this.props
          .loadClients()
          .then(() => {
            const selectedClient = this.props.clientsData.find(
              (client) => client.clientGuid === this.props.viewParams.clientGuid
            );
            this.props.dispatchSelectedClient(selectedClient);
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/unauthorized' });
            } else {
              NotificationManager.error(error.response?.message || 'Network Error');
              oktaAuth.signOut();
            }
          });
      }

      if (stateLoadAssumptionsNotRequested && propsAssumptionsIsEmpty) {
        this.setState({ loadAssumptionsRequested: true });
        this.props.loadAssumptions();
      }

      if (stateLoadRiskProxiesNotRequested) {
        this.setState({ loadRiskProxiesRequested: true });
        this.props
          .loadRiskProxies(this.props.viewParams.clientGuid)
          .then(() => {
            if (!this.props.riskProxies.length) {
              this.setState({ returnToCards: true });
            }
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/unauthorized' });
            } else {
              NotificationManager.error(error.response?.message || 'Network Error');
              oktaAuth.signOut();
            }
          });
      }

      if (stateLoadStudyDataNotRequested) {
        this.setState({ loadStudyDataRequested: true });
        this.props.loadStudyData(this.props.viewParams.studyId).then(() => {
          this.setState({ loaded: true });
          this.props.selectMeasure();
        });
      }

      if (
        (stateLoadedHasChanges || propsStudyHasChanges || propsStudyResourcesHasChanges) &&
        this.state.loaded &&
        propsStudyIsEmpty &&
        propsStudyResourcesExists
      ) {
        this.props.selectStudy(this.props.studyResources);
        this.props.loadStudyUsers(this.props.studyResources.study.clientGuid);
        this.props.loadStudyUsersAccess(this.props.studyResources.study.studyId);
        this.props.dispatchOriginalVersion();
        this.props.dispatchToExportStudy(this.props.studyResources);
      }
    }
  }

  confirmRevertStudy = () =>
    confirmDialog({
      message: 'This action will revert all values to the last saving. Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: this.revertStudy,
    });

  confirmQuitStudy = () => {
    if (this.props.isStudyModified()) {
      return confirmDialog({
        message: `Save the changes on ${this.props.studyProps.study.studyName}?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Save',
        rejectLabel: "Don't save",
        accept: () => {
          this.saveStudy();
          this.setState({ returnToCards: true });
        },
        reject: () => this.setState({ returnToCards: true }),
      });
    } else {
      this.setState({ returnToCards: true });
    }
  };

  confirmDeleteStudy = () =>
    confirmDialog({
      message: 'This action will delete this study and all its values. Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: this.handleDeleteStudy,
    });

  handleDeleteStudy = () => this.props.deleteStudy(this.props.viewParams.studyId).then(() => this.redirectToCards());

  handleCloneStudy = () => this.props.cloneStudy(this.props.viewParams.studyId).then(() => this.redirectToCards());

  redirectToCards = () => this.setState({ returnToCards: true });

  revertStudy = () => {
    const { studyProps } = this.props;
    this.setState({ study: studyProps.study, invalidateState: true });
    this.props.selectStudy(this.props.originalVersion);
    this.props.setMustRevert();
  };

  toggleDropMenu = () => {
    const { dropMenuOpen } = this.state;
    this.props.loadStudyUsers(this.props.studyResources.study.clientGuid);
    this.props.loadStudyUsersAccess(this.props.studyResources.study.studyId);
    this.setState({ dropMenuOpen: !dropMenuOpen });
  };

  handleStudyNameChange = ({ target: { value } }) => {
    this.props.dispatchStudyName(value);
  };

  handlePrint = () => setTimeout(() => this.props.dispatchPrinting(true), 200);

  saveStudy = () => {
    const { studyIsValid } = this.props.dispatchCheckStudyValidity();
    const [isValid, error] = studyIsValid;
    if (error) return NotificationManager.error(error);
    if (isValid) {
      this.props.saveStudy();
      this.props.dispatchOriginalVersion();
    }
  };

  exportStudyToExcel = () => {
    const { studyIsValid } = this.props.dispatchCheckStudyValidity();
    const [isValid, error] = studyIsValid;
    if (error) return NotificationManager.error(error);
    if (isValid) {
      this.props.exportStudyToExcel();
    }
  };

  stateAndPropsStudyIsNotSame = () => {
    const { study } = this.state;
    const { studyProps } = this.props;
    return study && studyProps.study && study.studyId !== studyProps.study.studyId;
  };

  render() {
    const { dropMenuOpen, study, returnToCards } = this.state;
    const { studyProps, displaySingleColumnLayout } = this.props;

    if (returnToCards) {
      return <Redirect to="/" />;
    }

    if (studyProps.study && study) {
      return (
        <StudyStyle>
          <Tooltip target="h3" position="top" showDelay={500} hideDelay={0} />
          <ConfirmDialog />
          <Dialog
            visible={this.state.securityVisible}
            onHide={() => this.setState({ securityVisible: false })}
            header="Users with access"
            icon="pi pi-exclamation-triangle"
            style={{ width: '50vw' }}
          >
            <LevelSecurity
              studyId={studyProps.study.studyId}
              clientGuid={studyProps.study.clientGuid}
              owner={studyProps.study.createdBy}
              hideDialog={() => this.setState({ securityVisible: false })}
            />
          </Dialog>
          <StudyHeader>
            <StudyTittle>
              <ManagementButton backIcon="pi pi-angle-left" action={this.confirmQuitStudy} circle />
              <ButtonDropdown isOpen={dropMenuOpen} toggle={this.toggleDropMenu}>
                <DropdownToggle color="" className="mainToggle" id="main-study-toggle">
                  <ToggleText id="study-title-dropdown">
                    <h3 data-pr-tooltip={this.props.studyProps.study.studyName}>{studyProps.study.studyName}</h3>
                    <i className="pi pi-bars"></i>
                  </ToggleText>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>
                    <Label>Study name:</Label>
                  </DropdownItem>
                  <DropdownItem header>
                    <InputGroup size="sm" className="StudyNameInput">
                      <Input
                        type="name"
                        name="name"
                        id="study-name-input"
                        value={this.props.studyProps.study.studyName}
                        onChange={(event) => this.handleStudyNameChange(event)}
                      />
                    </InputGroup>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => this.saveStudy()}
                    disabled={!this.props.isStudyModified()}
                    id="save-study-in-dropdown"
                  >
                    <i className="pi pi-save"></i>
                    Save Study...
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.confirmRevertStudy()}
                    disabled={!this.props.isStudyModified()}
                    id="revert-study"
                  >
                    <i className="pi pi-replay"></i>
                    Revert Study...
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => this.handleCloneStudy()}
                    disabled={this.props.isStudyModified()}
                    id="clone-study"
                  >
                    <i className="pi pi-clone"></i>
                    Clone Study...
                  </DropdownItem>
                  <DropdownItem onClick={() => this.confirmDeleteStudy()} id="delete-study">
                    <i className="pi pi-trash"></i>
                    Delete Study...
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.exportStudyToExcel()} id="export">
                    <i className="pi pi-file-excel"></i>
                    Export to Excel
                  </DropdownItem>
                  <DropdownItem onClick={() => this.props.toggleSettingsMenu()} id="open-settings-in-dropdown">
                    <i className="pi pi-cog"></i>
                    Study settings...
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      this.props.loadStudyUsersAccess(studyProps.study.studyId);
                      this.setState({ securityVisible: true });
                    }}
                    id="open-security-settings"
                  >
                    <i className="pi pi-shield"></i>
                    Security
                  </DropdownItem>
                  {Boolean(this.props.isStudyModified()) && (
                    <UncontrolledTooltip placement="right" trigger="hover" target="clone-study">
                      Save or Revert before cloning
                    </UncontrolledTooltip>
                  )}
                </DropdownMenu>
              </ButtonDropdown>
            </StudyTittle>
            <ActionButtons>
              {studyProps.study.lastCalculation ? (
                <LastCalculation>
                  Last calculation: {new Date(studyProps.study.lastCalculation).toLocaleString('en-US')}
                </LastCalculation>
              ) : (
                ''
              )}
              <div className="p-field-checkbox">
                <Checkbox
                  inputId="binary-column-view"
                  checked={displaySingleColumnLayout}
                  onChange={(event) => this.props.dispatchDisplaySingleColumnLayout(event.checked)}
                />
                <label htmlFor="binary-column-view">Single column layout</label>
              </div>
              <ManagementButton
                backIcon="pi-save"
                btnText="Save"
                id="save-button"
                action={this.saveStudy}
                disabled={!this.props.isStudyModified()}
                border
              />
              <ManagementButton
                backIcon="pi-file-pdf"
                btnText="Print PDF"
                action={this.handlePrint}
                id="print-pdf-button"
                border
              />
              <ManagementButton
                backIcon="pi-cog"
                action={this.props.toggleSettingsMenu}
                id="open-settings-button"
                border
              />
            </ActionButtons>
          </StudyHeader>
          <Boxes>
            <BoxInput>
              <AssetPools />
              <CashFlows />
              <OtherFinancialProjections />
              <FinancialMetrics />
            </BoxInput>
            <BoxDisplay>
              <EconomicScenarios />
              <AssetAllocation />
            </BoxDisplay>
          </Boxes>
          <StudySettingsPanel />
        </StudyStyle>
      );
    }
    return null;
  }
}

Study.propTypes = {
  assumptions: PropTypes.array,
  clientsData: PropTypes.array,
  cloneStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
  dispatchCheckStudyValidity: PropTypes.func,
  dispatchDisplaySingleColumnLayout: PropTypes.func,
  dispatchOriginalVersion: PropTypes.func,
  dispatchPrinting: PropTypes.func,
  dispatchSelectedClient: PropTypes.func,
  dispatchStudyName: PropTypes.func,
  dispatchToExportStudy: PropTypes.func,
  displaySingleColumnLayout: PropTypes.bool,
  exportStudyToExcel: PropTypes.func,
  loadAssumptions: PropTypes.func,
  loadClients: PropTypes.func,
  loadStudyUsers: PropTypes.func,
  loadRiskProxies: PropTypes.func,
  loadStudiesData: PropTypes.func,
  loadStudyData: PropTypes.func,
  okta: PropTypes.object,
  originalVersion: PropTypes.object,
  printing: PropTypes.bool,
  printStudyData: PropTypes.func,
  riskProxies: PropTypes.array,
  saveStudy: PropTypes.func,
  selectedClient: PropTypes.object,
  selectMeasure: PropTypes.func,
  selectStudy: PropTypes.func,
  setMustRevert: PropTypes.func,
  studyProps: PropTypes.object,
  studyResources: PropTypes.object,
  toggleSettingsMenu: PropTypes.func,
  viewParams: PropTypes.object,
  isStudyModified: PropTypes.func,
  loadStudyUsersAccess: PropTypes.func,
};

const mapStateToProps = (state) => ({
  assumptions: state.resources.assumptions.data || [],
  clientsData: state.resources.clients.data || [],
  displaySingleColumnLayout: state.ui.displaySingleColumnLayout,
  okta: state.ui.okta || {},
  originalVersion: state.ui.originalVersion,
  printing: state.ui.printing,
  riskProxies: state.resources.risk_proxies.data || [],
  selectedClient: state.ui.selectedClient || {},
  studyProps: state.ui.selectedStudy || {},
  studyResources: state.resources.currentstudyresources.data || {},
});

export default connect(mapStateToProps, {
  cloneStudy,
  deleteStudy,
  dispatchCheckStudyValidity,
  dispatchDisplaySingleColumnLayout,
  dispatchOriginalVersion,
  dispatchPrinting,
  dispatchSelectedClient,
  dispatchStudyName,
  dispatchToExportStudy,
  exportStudyToExcel,
  loadAssumptions,
  loadClients,
  loadStudyUsers,
  loadRiskProxies,
  loadStudiesData,
  loadStudyData,
  printStudyData,
  saveStudy,
  selectMeasure,
  selectStudy,
  setMustRevert,
  toggleSettingsMenu,
  isStudyModified,
  loadStudyUsersAccess,
})(Study);
