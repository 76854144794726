import axios from 'axios';

import Constants from './Constants';
import { getHTTPHeaders } from './common';

const denodoService = () => {
  const URL = Constants.DENODO.URL;

  const getRiskProxies = (clientGuid) =>
    axios.get(`${URL}/riskproxy/application/1/client/${clientGuid}`, getHTTPHeaders());

  const getAssetAllocation = (mixIds, allocatedBy) =>
    axios.get(`${URL}/asset/allocation/${mixIds}?allocatedBy=${allocatedBy}`, getHTTPHeaders());

  const getClients = (email) => axios.get(`${URL}/client?email=${email}`, getHTTPHeaders());

  const getUsersAccessData = (clientId) => axios.get(`${URL}/users?clientId=${clientId}`, getHTTPHeaders());

  const getSkyanAuthorization = (email) => axios.get(`${URL}/authorizeView?email=${email}`, getHTTPHeaders());

  const getAssumptions = () => axios.get(`${URL}/assumption`, getHTTPHeaders());

  return {
    getRiskProxies,
    getAssetAllocation,
    getClients,
    getAssumptions,
    getSkyanAuthorization,
    getUsersAccessData,
  };
};

export default denodoService;
