import styled from 'styled-components';

const StyledEconomicScenarios = styled.div`
  padding-top: 20px;
  min-height: 25rem;
  .sm-visible {
    display: none;
  }

  .p-button {
    max-width: 150px;
  }

  @media screen and (max-width: 40em) {
    .sm-invisible {
      display: none;
    }
    .sm-visible {
      display: inline;
      margin-right: 0.5rem;
    }
  }

  .economic-scenarios-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0px 20px;

    .main-header {
      display: flex;
      justify-content: space-between;
      margin-right: 1rem;
      .p-field-checkbox {
        .p-checkbox {
          margin-bottom: 2px;
          margin-right: 3px;
        }
      }
    }

    .header-inputs {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;
      .dropdown {
        :hover {
          box-shadow: 0 0 0 0.2rem #bfdbfe;
        }
        background: #ffffff;
        border: 1px solid #ced4da;
        transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
        border-radius: 4px;
        margin-right: 24px;
        padding: 5px 0px 5px 10px;
        .p-component {
          color: #002060;
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
    .p-button-outlined {
      margin-right: 15px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 4px 8px;
      background: #ffffff;
      border: 1px solid #c9d4de;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .p-button {
      color: #16709e;
      background: #ffffff;
      padding: 0.2rem 0.5rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      border-radius: 3px;
    }

    .p-button:hover {
      background: #16709e;
    }

    .multiselect {
      margin-right: 1rem;
      margin-left: 1rem;
      font-size: small;

      .p-multiselect {
        min-width: 15rem;
        height: auto;
        .p-multiselect-label {
          display: contents;
          .p-multiselect-token {
            margin: 0.25rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 660px) {
    .economic-scenarios-header {
      .main-header {
        display: block;
        .p-field-checkbox {
          text-align: center;
          margin: 5px 0px;
        }
      }
    }
  }

  tr > th {
    color: #000000;
    font-size: 14px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    background-color: #b6b6b6;
    white-space: nowrap;
    overflow: initial;
  }

  .economic-scenarios-table {
    .scenario {
      text-align: left;
      padding-left: 4px;
      width: 350px;
    }
    .p-inputtext {
      text-align: left;
      width: 35%;
      .p-inputtext {
        text-align: left;
      }
    }

    .desc-item {
      display: inline-flex;
    }

    td,
    th {
      color: #002060;
      border: 1px solid #b6b6b6;
      font-weight: 600;
      padding-right: 3px;
      .scenarios-description-header {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }

    &.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
      background: #dbe7f2;
      color: #495057;
      .p-button {
        transition: color 0.5s;
        color: #16709e;
      }
    }

    .p-treetable-tbody {
      color: #002060;
      max-width: 73px;
      font-size: 14px;
      line-height: 32px;
      text-align: right;
    }

    .p-treetable-tbody > tr.p-highlight {
      background: #dbe7f2;
      color: #495057;
      .p-button {
        transition: color 0.5s;
        color: #16709e;
      }
    }

    .p-treetable-tbody > tr {
      background: #ffffff;
      color: #495057;
      outline-color: #a6d5fa;
      font-weight: 400;
      .p-button {
        color: transparent;
        border: transparent;
        background: transparent;
      }
    }

    .p-treetable-thead > tr > th {
      font-weight: 600;
      background: #c9d4de;
    }

    .p-treetable-toggler {
      padding: 0 0.5rem;
    }
  }

  .p-button {
    text-align: left;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default StyledEconomicScenarios;
