import { Constants } from '../../services/Constants';
import { CATEGORIES } from '../CashFlows/CalcCashFlows';

// #region Other Financial Projections Calculations
export const _getDebtPrincipalPayments = (calculatedCashFlows) =>
  calculatedCashFlows?.sumByCategory
    .filter((item) => item.description === 'Debt')
    ?.map(({ year0, year1, year2, year3, year4, year5 }) => ({ year0, year1, year2, year3, year4, year5 }))[0] || {
    year0: 0,
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
  };

export const _getOperatingIncome = ({ operatingRevenue, operatingExpense }) => ({
  description: 'operatingIncome',
  year0: operatingRevenue.year0 - Math.abs(operatingExpense.year0),
  year1: operatingRevenue.year1 - Math.abs(operatingExpense.year1),
  year2: operatingRevenue.year2 - Math.abs(operatingExpense.year2),
  year3: operatingRevenue.year3 - Math.abs(operatingExpense.year3),
  year4: operatingRevenue.year4 - Math.abs(operatingExpense.year4),
  year5: operatingRevenue.year5 - Math.abs(operatingExpense.year5),
});

export const _getOperatingEBIDA = ({ _operatingIncome, depreciationAndAmortization, debtInterestExpense }) => ({
  description: 'operatingEBIDA',
  year0: _operatingIncome.year0 + depreciationAndAmortization.year0 + debtInterestExpense?.year0,
  year1: _operatingIncome.year1 + depreciationAndAmortization.year1 + debtInterestExpense?.year1,
  year2: _operatingIncome.year2 + depreciationAndAmortization.year2 + debtInterestExpense?.year2,
  year3: _operatingIncome.year3 + depreciationAndAmortization.year3 + debtInterestExpense?.year3,
  year4: _operatingIncome.year4 + depreciationAndAmortization.year4 + debtInterestExpense?.year4,
  year5: _operatingIncome.year5 + depreciationAndAmortization.year5 + debtInterestExpense?.year5,
});

export const _getUnrestrictedAssets = ({ assetPools, netCashFlow }) => {
  const totalAUM = assetPools.reduce((acc, cur) => {
    return acc + cur.aum;
  }, 0);
  const year0 = totalAUM;
  const year1 = year0 + netCashFlow.year1;
  const year2 = year1 + netCashFlow.year2;
  const year3 = year2 + netCashFlow.year3;
  const year4 = year3 + netCashFlow.year4;
  const year5 = year4 + netCashFlow.year5;
  return { description: 'unrestrictedAssets', year0, year1, year2, year3, year4, year5 };
};

export const _getNetCashFlow = ({ cashFlows, study }) => {
  const totalCashFlow = cashFlows.reduce(
    (acc, cur) => ({
      year1: acc.year1 + cur.year1,
      year2: acc.year2 + cur.year2,
      year3: acc.year3 + cur.year3,
      year4: acc.year4 + cur.year4,
      year5: acc.year5 + cur.year5,
    }),
    { year1: 0, year2: 0, year3: 0, year4: 0, year5: 0 }
  );
  return {
    year1: totalCashFlow.year1 + study.budgetedInvestmentGainLossYear1,
    year2: totalCashFlow.year2 + study.budgetedInvestmentGainLossYear2,
    year3: totalCashFlow.year3 + study.budgetedInvestmentGainLossYear3,
    year4: totalCashFlow.year4 + study.budgetedInvestmentGainLossYear4,
    year5: totalCashFlow.year5 + study.budgetedInvestmentGainLossYear5,
  };
};

// #endregion Other Financial Projections Calculations

// #region Main Functions
export const calcOtherFinancialProjections = (ui) => {
  if (!ui.selectedStudy?.calculatedCashFlows) return;

  const {
    selectedStudy: {
      assetPools,
      calculatedCashFlows,
      cashFlows,
      otherFinancialProjections: {
        depreciationAndAmortization,
        debtInterestExpense,
        operatingIncome: _operatingIncome,
      },
      study,
    },
  } = ui;

  const operationCategory = CATEGORIES.find((category) => category.name === 'Operation');
  const operatingRevenue = cashFlows.find(
    (cashFlow) =>
      cashFlow.categoryId === operationCategory.id && cashFlow.description === Constants.OPERATING_REVENUE_TITLE
  );
  const operatingExpense = cashFlows.find(
    (cashFlow) =>
      cashFlow.categoryId === operationCategory.id && cashFlow.description === Constants.OPERATING_EXPENSE_TITLE
  );

  const debtPrincipalPayments = _getDebtPrincipalPayments(calculatedCashFlows);
  const operatingIncome = _operatingIncome.isManualInput
    ? _operatingIncome
    : _getOperatingIncome({ operatingRevenue, operatingExpense });
  const netCashFlow = _getNetCashFlow({ cashFlows, study });
  const unrestrictedAssets = _getUnrestrictedAssets({ assetPools, netCashFlow });

  return {
    debtPrincipalPayments,
    operatingIncome,
    operatingEBIDA: _getOperatingEBIDA({ _operatingIncome, depreciationAndAmortization, debtInterestExpense }),
    unrestrictedAssets,
  };
};
// #endregion Main Functions
