import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';

import { Button, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';

import Constants from '../../services/Constants';
import { calcOtherFinancialProjections } from './CalcOtherFinancialProjections';
import DefaultBox from '../DefaultBox';
import StyledOtherFinancialProjections from './StyledOtherFinancialProjections';
import { dispatchUpdateOtherFinancialProjections } from '../../actions';
import { confirmDialog } from 'primereact/confirmdialog';
import { formatPrecisionNumber, isEqualFromYear0 } from '../../utils/utils';

const OtherFinancialProjections = (props) => {
  const { calculatedOtherFinancialProjections, measureScale, selectedStudy } = props;

  const { decimalPrecision } = selectedStudy.study;

  const parseNodes = (otherFinancialProjections) => {
    const rightOrder = Object.keys(Constants.OTHER_FINANCIAL_PROJECTIONS_DEFS);
    const parsedNodes = rightOrder.map((key) => {
      const children = Constants.OTHER_FINANCIAL_PROJECTIONS_DEFS[key].children.map((child) => {
        if (!child.children) {
          return {
            ...child,
            ...otherFinancialProjections[child.description],
          };
        } else {
          return {
            ...child,
            ...otherFinancialProjections[child.description],
            children: child.children.map((grandChild) => ({
              ...grandChild,
              ...otherFinancialProjections[grandChild.description],
            })),
          };
        }
      });
      return {
        description: key,
        ...Constants.OTHER_FINANCIAL_PROJECTIONS_DEFS[key],
        children: [...children],
        ...otherFinancialProjections[key],
      };
    });
    return parsedNodes;
  };

  const isManualInput = (fieldName) => selectedStudy.otherFinancialProjections?.[fieldName]?.isManualInput;

  const [nodesState, setNodesState] = useState([]);

  useEffect(() => {
    setNodesState(parseNodes(selectedStudy.otherFinancialProjections));
  }, [selectedStudy.otherFinancialProjections]);

  useEffect(() => {
    if (
      !isManualInput('operatingIncome') &&
      !isEqualFromYear0(
        selectedStudy.otherFinancialProjections.operatingIncome,
        calculatedOtherFinancialProjections.operatingIncome
      )
    ) {
      props.dispatchUpdateOtherFinancialProjections({
        operatingIncome: {
          description: 'operatingIncome',
          ...selectedStudy.otherFinancialProjections.operatingIncome,
          ...calculatedOtherFinancialProjections.operatingIncome,
        },
      });
    }
  }, [calculatedOtherFinancialProjections.operatingIncome]);

  useEffect(() => {
    if (
      !isManualInput('unrestrictedAssets') &&
      !isEqualFromYear0(
        selectedStudy.otherFinancialProjections.unrestrictedAssets,
        calculatedOtherFinancialProjections.unrestrictedAssets
      )
    ) {
      props.dispatchUpdateOtherFinancialProjections({
        unrestrictedAssets: {
          description: 'unrestrictedAssets',
          ...selectedStudy.otherFinancialProjections.unrestrictedAssets,
          ...calculatedOtherFinancialProjections.unrestrictedAssets,
        },
      });
    }
  }, [calculatedOtherFinancialProjections.unrestrictedAssets]);

  useEffect(() => {
    if (
      !isEqualFromYear0(
        selectedStudy.otherFinancialProjections.operatingEBIDA,
        calculatedOtherFinancialProjections.operatingEBIDA
      )
    ) {
      props.dispatchUpdateOtherFinancialProjections({
        operatingEBIDA: {
          description: 'operatingEBIDA',
          ...selectedStudy.otherFinancialProjections.operatingEBIDA,
          ...calculatedOtherFinancialProjections.operatingEBIDA,
        },
      });
    }
  }, [calculatedOtherFinancialProjections.operatingEBIDA]);

  const getDisabledInput = (rowData, field) => {
    const { readOnlyFrom, isManualInput } = rowData;
    if (!readOnlyFrom) return false;
    else {
      if (readOnlyFrom === 'year0') return !isManualInput;
      if (readOnlyFrom === 'year1') return field !== 'year0' && !isManualInput;
    }
  };

  const numberEditor = (rowData, colBodyOptions) => (
    <>
      <InputNumber
        key="1"
        id={`${rowData.description}-${colBodyOptions.field}`}
        value={rowData[colBodyOptions.field]}
        className="table-editor noPrint"
        onChange={(event) =>
          props.dispatchUpdateOtherFinancialProjections({
            [rowData.description]: { ...rowData, [colBodyOptions.field]: event.value },
          })
        }
        allowEmpty={false}
        inputMode="number"
        mode="decimal"
        locale="en-US"
        minFractionDigits={decimalPrecision}
        maxFractionDigits={decimalPrecision}
        disabled={getDisabledInput(rowData, colBodyOptions.field)}
        // min={0}
        onFocus={(event) => {
          event.target.selectionStart = 0;
          event.target.selectionEnd = event.target.value.length;
        }}
      />
      {Boolean(getDisabledInput(rowData, colBodyOptions.field)) && (
        <UncontrolledTooltip placement="top" trigger="hover" target={`${rowData.description}-${colBodyOptions.field}`}>
          Read Only
        </UncontrolledTooltip>
      )}
      <div className="printOnly">{formatPrecisionNumber(rowData[colBodyOptions.field], decimalPrecision)}</div>
    </>
  );

  const rowClassName = (rowData) => {
    const baseClassName = 'other-financial-projections-row';
    const fieldName = `field-name-${rowData.description.toLowerCase()}`;
    const hasChild = `has-child-${Boolean(rowData.children?.length > 0)}`;
    const isChild = `is-child-${Boolean(rowData.key.length > 1 && rowData.key.length < 4)}`;
    const isGrandChild = `is-grand-child-${Boolean(rowData.key.length > 3)}`;
    const isManualInput = `is-manual-input-${rowData.isManualInput}`;
    const marked = `marked-${rowData.markedRow}`;
    const readOnlyFrom = `read-only-from-${rowData.readOnlyFrom}`;
    const especificRowClassName = `${baseClassName} ${fieldName} ${hasChild} ${isChild} ${isGrandChild} ${isManualInput} ${marked} ${readOnlyFrom}`;
    return { [especificRowClassName]: true };
  };

  const resetManualInputToCalc = (fieldName) => {
    props.dispatchUpdateOtherFinancialProjections({
      [fieldName]: {
        ...selectedStudy.otherFinancialProjections[fieldName],
        ...calculatedOtherFinancialProjections[fieldName],
        isManualInput: false,
      },
    });
  };

  const manualInputTDSConfirmDialog = (rowData) => {
    confirmDialog({
      message: `This action will reset ${rowData.title} manual input values to calculated ones. Are you sure you want to proceed?`,
      header: 'Reset Manual Input Values',
      icon: 'pi pi-exclamation-triangle',
      accept: () => resetManualInputToCalc(rowData.description),
    });
  };

  const manualInputOiConfirmDialog = (rowData) => {
    confirmDialog({
      message: `This action will make use of calculated values based on Operation Revenue/Expense. Are you sure you want to proceed?`,
      header: 'Use Cash Flow Operation OR/OE',
      icon: 'pi pi-exclamation-triangle',
      accept: () => resetManualInputToCalc(rowData.description),
    });
  };

  const handleManualInputUACheckboxChange = (checked, rowData) => {
    if (checked) {
      manualInputTDSConfirmDialog(rowData);
    } else {
      props.dispatchUpdateOtherFinancialProjections({
        [rowData.description]: { ...rowData, isManualInput: true },
      });
    }
  };

  const handleManualInputOICheckboxChange = (checked, rowData) => {
    if (checked) {
      manualInputOiConfirmDialog(rowData);
    } else {
      props.dispatchUpdateOtherFinancialProjections({
        [rowData.description]: { ...rowData, isManualInput: true },
      });
    }
  };

  const getDescriptionBody = (rowData) => (
    <div className="description">
      <div id={`${rowData.description.toLowerCase()}-description`}>{rowData.title}</div>
      {rowData.description === 'operatingIncome' ? (
        <>
          <FormGroup check inline id="manual-input-oi" className="noPrint">
            <Input
              id="manual-input-checkbox-oi"
              type="checkbox"
              checked={!rowData.isManualInput}
              onChange={({ target: { checked } }) => handleManualInputOICheckboxChange(checked, rowData)}
            />
            <Label for="manual-input-checkbox-oi" check>
              Use CF Operation
            </Label>
          </FormGroup>
          <UncontrolledTooltip placement="top" trigger="hover" target="manual-input-oi">
            Check this box to automatically copy the values from the cash flow table. Calculation performed is: = (OR -
            OE)
          </UncontrolledTooltip>
        </>
      ) : null}
      {rowData.description === 'unrestrictedAssets' ? (
        <>
          <FormGroup check inline id="manual-input-ua" className="noPrint">
            <Input
              id="manual-input-checkbox-ua"
              type="checkbox"
              checked={!rowData.isManualInput}
              onChange={({ target: { checked } }) => handleManualInputUACheckboxChange(checked, rowData)}
            />
            <Label for="manual-input-checkbox-ua" check>
              Use Net Cash Flow
            </Label>
          </FormGroup>
          <UncontrolledTooltip placement="top" trigger="hover" target="manual-input-ua">
            Check this box to automatically copy the values from the cash flow table. Calculation performed is: =
            (previous year + Net Cash Flow)
          </UncontrolledTooltip>
        </>
      ) : null}
      {rowData.info ? (
        <div className="info">
          <Button id={`${rowData.description.toLowerCase()}-info`} color="link" className="link-info">
            <i className="pi pi-info-circle"></i>
          </Button>
          <UncontrolledTooltip placement="top" trigger="hover" target={`${rowData.description.toLowerCase()}-info`}>
            {rowData.info}
          </UncontrolledTooltip>
        </div>
      ) : null}
    </div>
  );

  return (
    <DefaultBox fullBorder={false} id="other-financial-projections-card">
      <StyledOtherFinancialProjections>
        <div className="other-financial-projections-header">
          <h2>Income & Other Financial Projections (${measureScale.measureSymbol})</h2>
        </div>

        {Boolean(nodesState) && (
          <div className="other-financial-projections-table">
            <TreeTable
              body={(rowData) => <span id={`${rowData.description}-title`}>{rowData.title}</span>}
              className="other-financial-projections"
              expandedKeys={{ 0: true }}
              responsiveLayout="scroll"
              rowClassName={rowClassName}
              value={nodesState}
            >
              <Column
                body={(rowData) => getDescriptionBody(rowData)}
                bodyClassName="description-body"
                expander
                header="Description"
                headerClassName="description-header"
                style={{ width: '35%' }}
              ></Column>
              <Column
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
                bodyClassName="year0-body"
                field="year0"
                header={selectedStudy.study.columnHead0Year}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
                bodyClassName="year1-body"
                field="year1"
                header={selectedStudy.study.columnHead1Year}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
                bodyClassName="year2-body"
                field="year2"
                header={selectedStudy.study.columnHead2Year}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
                bodyClassName="year3-body"
                field="year3"
                header={selectedStudy.study.columnHead3Year}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
                bodyClassName="year4-body"
                field="year4"
                header={selectedStudy.study.columnHead4Year}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
                bodyClassName="year5-body"
                field="year5"
                header={selectedStudy.study.columnHead5Year}
                style={{ width: '10.8%' }}
              ></Column>
            </TreeTable>
          </div>
        )}
      </StyledOtherFinancialProjections>
    </DefaultBox>
  );
};

OtherFinancialProjections.propTypes = {
  calculatedOtherFinancialProjections: PropTypes.object,
  measureScale: PropTypes.object,
  selectedStudy: PropTypes.object,
  dispatchUpdateOtherFinancialProjections: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    calculatedOtherFinancialProjections: calcOtherFinancialProjections(state.ui) || {},
    measureScale: state.ui.measureScale,
    selectedStudy: state.ui.selectedStudy || {},
  };
};

export default connect(mapStateToProps, { dispatchUpdateOtherFinancialProjections })(OtherFinancialProjections);
