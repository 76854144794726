import * as types from '../actions/Types';
import { addEvent, addLoginEvent } from './EventActions';
import { Constants, Events } from '../services/Constants';
import download from 'downloadjs';

export const redirect = (to) => {
  return (dispatch) => {
    dispatch({ type: types.REDIRECT, to });
  };
};

export const setMustRevert = () => {
  return (dispatch) => {
    dispatch({ type: types.MUST_REVERT });
  };
};

export const setCredentials = (oktaData) => {
  return (dispatch, getState) => {
    const {
      ui: { okta },
    } = getState();
    if (!okta) {
      dispatch(addLoginEvent(Events.LOGIN, oktaData));
    }
    dispatch({ type: types.SET_CREDENTIALS, okta: oktaData });
  };
};

export const dispatchUserData = (oktaData) => {
  return (dispatch) => {
    dispatch({ type: types.USER_DATA, oktaData });
  };
};

export const resetCredentials = () => {
  return (dispatch) => {
    return dispatch({ type: types.RESET_CREDENTIALS });
  };
};

export const selectStudy = (studyData) => {
  return (dispatch) => {
    return dispatch({
      type: types.SET_SELECTED_STUDY,
      selectedStudy: studyData,
    });
  };
};

export const storeEconomicScenarios = (economicScenarios) => {
  return (dispatch) => {
    return dispatch({
      type: types.STORE_ECONOMIC_SCENARIOS,
      ...economicScenarios,
    });
  };
};

export const storeAUMAndReturn = (graphData) => {
  return (dispatch, getState) => {
    const {
      ui: { selectedStudy },
    } = getState();
    if (selectedStudy) {
      return dispatch({ type: types.STORE_AUM_AND_RETURN, graphData });
    }
  };
};

export const dispatchStudyName = (studyName) => {
  return (dispatch, getState) => {
    const {
      ui: { selectedStudy },
    } = getState();

    const studyData = {
      ...selectedStudy,
      study: {
        ...selectedStudy.study,
        studyName,
      },
    };
    return dispatch({
      type: types.SET_SELECTED_STUDY,
      selectedStudy: studyData,
    });
  };
};

export const dispatchCashFlowsData = (cashFlows) => {
  return (dispatch) => {
    return dispatch({ type: types.CASH_FLOWS_DATA, cashFlows });
  };
};

export const dispatchCalculatedCashFlows = (calculatedCashFlows) => {
  return (dispatch) => {
    return dispatch({
      type: types.CALCULATED_CASH_FLOWS,
      calculatedCashFlows,
    });
  };
};

export const dispatchShockTest = (shockTestToCalc, shockTestToSave) => {
  return (dispatch) => {
    return dispatch({
      type: types.SHOCK_TEST,
      shockTestToCalc,
      shockTestToSave,
    });
  };
};

export const dispatchIsRelativeToBudget = (isRelativeToBudget) => {
  return (dispatch) => {
    return dispatch({ type: types.IS_RELATIVE_TO_BUDGET, isRelativeToBudget });
  };
};

export const dispatchDisplaySingleColumnLayout = (displaySingleColumnLayout) => {
  return (dispatch) => {
    const display = displaySingleColumnLayout ? 1 : 2;
    dispatch(addEvent(Events.CHANGE_THE_COLUMNS_VIEW, display));
    return dispatch({ type: types.DISPLAY_SINGLE_COLUMN_LAYOUT, displaySingleColumnLayout });
  };
};

export const dispatchAssetPoolsData = (assetPools) => {
  return (dispatch, getState) => {
    const { ui } = getState();

    const selectedStudy = {
      ...ui.selectedStudy,
      assetPools: [...assetPools],
    };
    return dispatch({ type: types.SET_SELECTED_STUDY, selectedStudy });
  };
};

export const dispatchToExportStudy = (_toExportStudy) => {
  return (dispatch, getState) => {
    const { ui } = getState();

    const toExportStudy = {
      ...ui.toExportStudy,
      ..._toExportStudy,
    };
    return dispatch({ type: types.SET_TO_EXPORT_STUDY, toExportStudy });
  };
};

export const dispatchSelectedAllocatedByValue = (selectedAllocatedByValue) => {
  return (dispatch) => {
    return dispatch({ type: types.SET_SELECTED_ALLOCATED_BY_VALUE, selectedAllocatedByValue });
  };
};

export const dispatchUpdateOtherFinancialProjections = (data) => {
  return (dispatch) => {
    delete data[Object.keys(data)[0]].title;
    delete data[Object.keys(data)[0]].key;
    delete data[Object.keys(data)[0]].readOnly;
    delete data[Object.keys(data)[0]].markedRow;
    return dispatch({
      type: types.UPDATE_OTHER_FINANCIAL_PROJECTIONS_DATA,
      otherFinancialProjections: data,
    });
  };
};

export const dispatchSetFinancialMetrics = (data) => {
  return (dispatch) => {
    return dispatch({
      type: types.SET_FINANCIAL_METRICS_DATA,
      financialMetrics: data,
    });
  };
};

export const dispatchUpdateStudy = (data) => (dispatch, getState) =>
  dispatch({
    type: types.UPDATE_STUDY_DATA,
    study: {
      ...getState().ui.selectedStudy.study,
      ...data,
    },
  });

export const dispatchCleanUpCalcValues = () => (dispatch, getState) => {
  const { ui } = getState();

  const selectedStudy = {
    ...ui.selectedStudy,
    assetPools: ui.selectedStudy.assetPools.map((asset) => ({
      ...asset,
      expRtnGeo10Yrs: undefined,
      standardDev: undefined,
    })),
    study: {
      ...ui.selectedStudy.study,
      expRtnGeo10Yrs: undefined,
      standardDev: undefined,
      lastCalculation: undefined,
    },
    economicScenarios: [],
  };

  return dispatch({ type: types.SET_SELECTED_STUDY, selectedStudy });
};

export const dispatchRiskReturnResponse = (data, lastCalculation) => (dispatch, getState) => {
  const { ui } = getState();

  const selectedStudy = {
    ...ui.selectedStudy,
    assetPools: ui.selectedStudy.assetPools.map((asset) => {
      const { expRtnGeo10Yrs, standardDev } = data['components'].find((item) => item.id === asset.assetPoolId);
      return {
        ...asset,
        expRtnGeo10Yrs,
        standardDev,
      };
    }),
    study: {
      ...ui.selectedStudy.study,
      expRtnGeo10Yrs: data['total'].expRtnGeo10Yrs,
      standardDev: data['total'].standardDev,
      lastCalculation,
    },
    economicScenarios: data['total']['scenarios'],
  };

  return dispatch({ type: types.SET_SELECTED_STUDY, selectedStudy });
};

export const selectAssumption = (assumption) => {
  return (dispatch) => {
    dispatch(addEvent(Events.CHANGE_ASSUMPTION, assumption));
    dispatch({ type: types.SELECTED_ASSUMPTION, assumption });
  };
};

export const changeMeasure = (measureScale) => {
  return (dispatch) => {
    dispatch(addEvent(Events.CHANGE_MEASURE_SCALE, measureScale.measureId));
    dispatch({ type: types.SELECTED_MEASURE, measureScale });
  };
};

export const dispatchTotalMarketValue = (totalMarketValue) => {
  return (dispatch) => {
    dispatch({ type: types.SET_TOTAL_MARKET_VALUE, totalMarketValue });
  };
};

export const dispatchEmptyAssetPoolDescription = (emptyAssetPoolDescription) => {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_ASSET_POOL_DESCRIPTION,
      emptyAssetPoolDescription,
    });
  };
};

export const dispatchEmptyAssetPoolRiskProxy = (emptyAssetPoolRiskProxy) => {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_ASSET_POOL_RISK_PROXY,
      emptyAssetPoolRiskProxy,
    });
  };
};

export const dispatchSelectedClient = (selectedClient) => {
  return (dispatch) => {
    dispatch(addEvent(Events.SELECT_CLIENT, selectedClient?.name));
    dispatch({ type: types.SET_SELECTED_CLIENT, selectedClient });
  };
};

export const toggleSettingsMenu = () => {
  return (dispatch) => {
    dispatch({ type: types.TOGGLE_SETTINGS_MENU });
  };
};

export const dispatchOriginalVersion = () => {
  return (dispatch) => {
    dispatch({ type: types.ORIGINAL_VERSION });
  };
};

export const openFile = (fileName, contents) => {
  download(contents, fileName.replaceAll(' ', ''));
};

export const dispatchCheckStudyValidity = () => (dispatch, getState) => {
  const {
    emptyAssetPoolDescription,
    emptyAssetPoolRiskProxy,
    selectedStudy: {
      study: { assumptionId },
    },
  } = getState().ui;
  if (emptyAssetPoolDescription) {
    return dispatch({
      type: types.IS_STUDY_VALID,
      studyIsValid: [false, `Please enter a description for the new pool`],
    });
  }
  if (emptyAssetPoolRiskProxy) {
    return dispatch({
      type: types.IS_STUDY_VALID,
      studyIsValid: [false, `Please enter a risk proxy for the new pool`],
    });
  }
  if (!assumptionId) {
    return dispatch({
      type: types.IS_STUDY_VALID,
      studyIsValid: [false, `Please choose an Assumption on Study Settings to perform calculations`],
    });
  }
  return dispatch({
    type: types.IS_STUDY_VALID,
    studyIsValid: [true, ''],
  });
};

export const dispatchPrinting = (printing) => {
  return (dispatch) => dispatch({ type: types.PRINTING, printing });
};

export const selectMeasure = () => {
  return (dispatch, getState) => {
    const {
      ui: { selectedStudy },
    } = getState();
    switch (selectedStudy?.study?.measureScaleId) {
      case 1:
        return dispatch({
          type: types.SELECTED_MEASURE,
          measureScale: { measureSymbol: '', measureLabel: 'None', measureId: 1 },
        });
      case 2:
        return dispatch({
          type: types.SELECTED_MEASURE,
          measureScale: { measureSymbol: '000', measureLabel: 'Thousands (000)', measureId: 2 },
        });
      case 3:
        return dispatch({
          type: types.SELECTED_MEASURE,
          measureScale: { measureSymbol: 'M', measureLabel: 'Millions (M)', measureId: 3 },
        });
      case 4:
        return dispatch({
          type: types.SELECTED_MEASURE,
          measureScale: { measureSymbol: 'B', measureLabel: 'Billions (B)', measureId: 4 },
        });
    }
  };
};

export const isStudyModified = () => {
  return (dispatch, getState) => {
    const {
      ui: { selectedStudy, originalVersion },
    } = getState();
    if (selectedStudy && originalVersion) {
      const _selectedStudy = { ...selectedStudy };
      delete _selectedStudy.shockTest;
      const currentStudyWithoutAssetPoolsMixes = {
        ..._selectedStudy,
        study: {
          ..._selectedStudy.study,
          expRtnGeo10Yrs: undefined,
          standardDev: undefined,
          lastCalculation: undefined,
          esBaseCaseAumYear0: undefined,
          esBaseCaseAumYear1: undefined,
          esBaseCaseAumYear2: undefined,
          esBaseCaseAumYear3: undefined,
          esBaseCaseAumYear4: undefined,
          esBaseCaseAumYear5: undefined,
        },
        assetPools: _selectedStudy.assetPools.map((item) => ({
          ...item,
          mixName: undefined,
          mixId: undefined,
          mixGuid: undefined,
          expRtnGeo10Yrs: undefined,
          standardDev: undefined,
          integrationId: undefined,
        })),
        financialMetrics: undefined,
        otherFinancialProjections: _selectedStudy.otherFinancialProjections,
        calculatedCashFlows: undefined,
        shockTests: _selectedStudy.shockTests,
      };
      const originalVersionWithoutAssetPoolsMixes = {
        ...originalVersion,
        study: {
          ...originalVersion.study,
          expRtnGeo10Yrs: undefined,
          standardDev: undefined,
          lastCalculation: undefined,
          esBaseCaseAumYear0: undefined,
          esBaseCaseAumYear1: undefined,
          esBaseCaseAumYear2: undefined,
          esBaseCaseAumYear3: undefined,
          esBaseCaseAumYear4: undefined,
          esBaseCaseAumYear5: undefined,
        },
        assetPools: originalVersion.assetPools.map((item) => ({
          ...item,
          mixName: undefined,
          mixId: undefined,
          mixGuid: undefined,
          expRtnGeo10Yrs: undefined,
          standardDev: undefined,
          integrationId: undefined,
        })),
        financialMetrics: undefined,
        otherFinancialProjections: originalVersion.otherFinancialProjections,
        calculatedCashFlows: undefined,
        shockTests: originalVersion.shockTests,
      };
      return (
        JSON.stringify(originalVersionWithoutAssetPoolsMixes).replaceAll('null', '0') !==
        JSON.stringify(currentStudyWithoutAssetPoolsMixes).replaceAll('null', '0')
      );
    } else return false;
  };
};

export const isFixedField = (title) => {
  return Boolean(title === Constants.OPERATING_EXPENSE_TITLE || title === Constants.OPERATING_REVENUE_TITLE);
};
